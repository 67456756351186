/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a",
    ul: "ul",
    li: "li",
    code: "code",
    pre: "pre",
    h3: "h3"
  }, _provideComponents(), props.components), {Aside} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "day-3-perfectly-spherical-houses-in-a-vacuum"
  }, "Day 3: Perfectly Spherical Houses in a Vacuum"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://adventofcode.com/2015/day/3"
  }, "https://adventofcode.com/2015/day/3")), "\n", React.createElement(Aside, null, React.createElement(_components.p, null, "TL;DR: ", React.createElement(_components.a, {
    href: "https://github.com/NickyMeuleman/scrapyard/blob/main/advent_of_code/2015/src/day_03.rs"
  }, "my solution in Rust"))), "\n", React.createElement(_components.p, null, "Santa is delivering presents to an infinite two-dimensional grid of houses."), "\n", React.createElement(_components.p, null, "Today’s input is a list of move instructions for Santa."), "\n", React.createElement(_components.p, null, "Moves are always a single step:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "^"), " North"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "v"), " South"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, ">"), " East"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "<"), " West"), "\n"), "\n", React.createElement(_components.p, null, "An example input looks like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt",
    title: "input.txt"
  }, "^^<<v<<v><v^^<><>^^<\n")), "\n", React.createElement(_components.p, null, "After every move, Santa delivers a present to the house at that coordinate."), "\n", React.createElement(_components.h2, {
    id: "part-1"
  }, "Part 1"), "\n", React.createElement(_components.p, null, "By following these instructions, Santa ends up visiting some houses multiple times."), "\n", React.createElement(_components.p, null, "The question asks how many houses receive at least one present."), "\n", React.createElement(_components.h3, {
    id: "helpers"
  }, "Helpers"), "\n", React.createElement(_components.p, null, "A data structure to keep track of a coordinate in that 2D space."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "#[derive(Clone, Copy, PartialEq, Eq, Hash)]\nstruct Coord {\n    x: i32,\n    y: i32,\n}\n")), "\n", React.createElement(_components.p, null, "About that ", React.createElement(_components.code, null, "derive"), " bit on top:\nThose are some ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/rust-by-example/trait/derive.html"
  }, "automatically derived ", React.createElement(_components.code, null, "traits")), " I use to be able to use ", React.createElement(_components.code, null, "Coord"), " in a few different ways.\nThey enable several behaviours of our ", React.createElement(_components.code, null, "Coord"), " struct.\nLike ", React.createElement(_components.code, null, "Eq"), " to be able to tell if two ", React.createElement(_components.code, null, "Coord"), "s are equal."), "\n", React.createElement(_components.h3, {
    id: "option-1-a-set-of-coordinates"
  }, "Option 1: A set of coordinates"), "\n", React.createElement(_components.p, null, "Santa starts at a random coordinate.\nI picked 0,0 but the starting location doesn’t matter."), "\n", React.createElement(_components.p, null, "After every move, the new coordinate is added to a set of visited houses.\nBecause it’s a set, it won’t store duplicates and only count a specific coordinate once."), "\n", React.createElement(_components.p, null, "At the end, the length of that set is the answer to part 1."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "pub fn part_1(input: &str) -> usize {\n    let mut santa = Coord { x: 0, y: 0 };\n    let mut visited: HashSet<Coord> = HashSet::new();\n    // visit starting point\n    visited.insert(santa);\n\n    for c in input.chars() {\n        // move to a house\n        match c {\n            '>' => santa.x += 1,\n            '<' => santa.x -= 1,\n            '^' => santa.y -= 1,\n            'v' => santa.y += 1,\n            _ => panic!(\"invalid input\"),\n        }\n        // visit a house\n        visited.insert(santa);\n    }\n\n    visited.len()\n}\n")), "\n", React.createElement(_components.h3, {
    id: "option-2-turning-the-list-of-moves-into-a-list-of-coordinates"
  }, "Option 2: Turning the list of moves into a list of coordinates"), "\n", React.createElement(_components.p, null, "This solution turns the iterator of moves into an iterator of coordinates.\nOnly the unique coordinates in that iterator are kept.\nThe amount of items in that iterator is the answer to part 1."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "use itertools::Itertools;\n\npub fn part_1(input: &str) -> usize {\n    input\n        .chars()\n        // turn into iterator of coordinates santa is at\n        .scan(Coord { x: 0, y: 0 }, |santa, c| {\n            match c {\n                '>' => santa.x += 1,\n                '<' => santa.x -= 1,\n                '^' => santa.y -= 1,\n                'v' => santa.y += 1,\n                _ => panic!(\"invalid input\"),\n            }\n            Some(*santa)\n        })\n        // filter out duplicates\n        .unique()\n        .count()\n}\n")), "\n", React.createElement(_components.h3, {
    id: "main-code-for-part-1"
  }, "Main code for part 1"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_03.rs"
  }, "use itertools::Itertools;\n\npub fn part_1(input: &str) -> usize {\n    input\n        .chars()\n        // turn into iterator of coordinates santa is at\n        .scan(Coord { x: 0, y: 0 }, |santa, c| {\n            match c {\n                '>' => santa.x += 1,\n                '<' => santa.x -= 1,\n                '^' => santa.y -= 1,\n                'v' => santa.y += 1,\n                _ => panic!(\"invalid input\"),\n            }\n            Some(*santa)\n        })\n        // filter out duplicates\n        .unique()\n        .count()\n}\n")), "\n", React.createElement(_components.h2, {
    id: "part-2"
  }, "Part 2"), "\n", React.createElement(_components.p, null, "The next year, Santa creates a robot that can deliver presents too, “Robo-Santa”."), "\n", React.createElement(_components.p, null, "They both start at the same coordinate."), "\n", React.createElement(_components.p, null, "They take turns moving based on the instructions."), "\n", React.createElement(_components.p, null, "The list of instructions is still the same one."), "\n", React.createElement(_components.p, null, "The question asks how many houses receive at least one present."), "\n", React.createElement(_components.h3, {
    id: "option-1-a-set-of-coordinates-1"
  }, "Option 1: A set of coordinates"), "\n", React.createElement(_components.p, null, "Similar to part 1, but keep track of 2 coordinates.\nTo check which santa moves, check if the index of the move is divisible by 2."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "pub fn part_2(input: &str) -> usize {\n    let mut santa = Coord { x: 0, y: 0 };\n    let mut robo_santa = Coord { x: 0, y: 0 };\n    let mut visited: HashSet<Coord> = HashSet::new();\n    visited.insert(santa);\n    visited.insert(robo_santa);\n\n    for (idx, c) in input.chars().enumerate() {\n        let mover = if idx % 2 == 0 { &mut santa } else { &mut robo_santa };\n        match c {\n            '>' => mover.x += 1,\n            '<' => mover.x -= 1,\n            '^' => mover.y -= 1,\n            'v' => mover.y += 1,\n            _ => panic!(\"invalid input\"),\n        }\n        visited.insert(*mover);\n    }\n\n    visited.len()\n}\n")), "\n", React.createElement(_components.h3, {
    id: "option-2-turning-the-list-of-moves-into-a-list-of-coordinates-1"
  }, "Option 2: Turning the list of moves into a list of coordinates"), "\n", React.createElement(_components.p, null, "Identical logic changes to convert from part 1 to part 2 as in Option 1:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "pub fn part_2(input: &str) -> usize {\n    input\n        .chars()\n        .enumerate()\n        // turn into iterator of coordinates (robo-)santa is at\n        .scan(\n            (Coord { x: 0, y: 0 }, Coord { x: 0, y: 0 }),\n            |(santa, robo_santa), (idx, c)| {\n                let mover = if idx % 2 == 0 { santa } else { robo_santa };\n                match c {\n                    '>' => mover.x += 1,\n                    '<' => mover.x -= 1,\n                    '^' => mover.y -= 1,\n                    'v' => mover.y += 1,\n                    _ => panic!(\"invalid input\"),\n                }\n                Some(*mover)\n            },\n        )\n        // filter out duplicates\n        .unique()\n        .count()\n}\n")), "\n", React.createElement(_components.h3, {
    id: "main-code-for-part-2"
  }, "Main code for part 2"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_03.rs"
  }, "use itertools::Itertools;\n\npub fn part_2(input: &str) -> usize {\n    input\n        .chars()\n        .enumerate()\n        // turn into iterator of coordinates (robo-)santa is at\n        .scan(\n            (Coord { x: 0, y: 0 }, Coord { x: 0, y: 0 }),\n            |(santa, robo_santa), (idx, c)| {\n                let mover = if idx % 2 == 0 { santa } else { robo_santa };\n                match c {\n                    '>' => mover.x += 1,\n                    '<' => mover.x -= 1,\n                    '^' => mover.y -= 1,\n                    'v' => mover.y += 1,\n                    _ => panic!(\"invalid input\"),\n                }\n                Some(*mover)\n            },\n        )\n        // filter out duplicates\n        .unique()\n        .count()\n}\n")), "\n", React.createElement(_components.h2, {
    id: "final-code"
  }, "Final code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_03.rs",
    numberLines: true
  }, "use itertools::Itertools;\n\n#[derive(Clone, Copy, PartialEq, Eq, Hash)]\nstruct Coord {\n    x: i32,\n    y: i32,\n}\n\npub fn part_1(input: &str) -> usize {\n    input\n        .chars()\n        // turn into iterator of coordinates santa is at\n        .scan(Coord { x: 0, y: 0 }, |santa, c| {\n            match c {\n                '>' => santa.x += 1,\n                '<' => santa.x -= 1,\n                '^' => santa.y -= 1,\n                'v' => santa.y += 1,\n                _ => panic!(\"invalid input\"),\n            }\n            Some(*santa)\n        })\n        // filter out duplicates\n        .unique()\n        .count()\n}\n\npub fn part_2(input: &str) -> usize {\n    input\n        .chars()\n        .enumerate()\n        // turn into iterator of coordinates (robo-)santa is at\n        .scan(\n            (Coord { x: 0, y: 0 }, Coord { x: 0, y: 0 }),\n            |(santa, robo_santa), (idx, c)| {\n                let mover = if idx % 2 == 0 { santa } else { robo_santa };\n                match c {\n                    '>' => mover.x += 1,\n                    '<' => mover.x -= 1,\n                    '^' => mover.y -= 1,\n                    'v' => mover.y += 1,\n                    _ => panic!(\"invalid input\"),\n                }\n                Some(*mover)\n            },\n        )\n        // filter out duplicates\n        .unique()\n        .count()\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
